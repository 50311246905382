<template>
    <settingsPagesLayout>
        <section class="wrap_s_block">
            <h2 class="name_section">Wallet</h2>
            <div class="body_ privacy">
                <h4>Under construction</h4>
            </div>
        </section>
    </settingsPagesLayout>
</template>
<script>
import settingsPagesLayout from "@/layouts/settingsPagesLayout";

export default {
    name: 'wallet',
    components: {
        settingsPagesLayout
    },
}
</script>
